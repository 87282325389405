<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
		    <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="处理描述">
          <a-textarea :disabled="disabled" :auto-size="{ minRows: 3}" placeholder="请输入处理描述" v-decorator="['handleContent', validatorRules.handleContent]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

  import pick from 'lodash.pick'
  import moment from "moment"
  import { getFeedback, addFeedback, updateFeedback, pageFeedback } from '@/api/user/feedback'
  import Editor from '@/components/Editor'
  export default {
    name: "feedBackModal",
    data () {
      return {
        title:"反馈处理",
        visible: false,
        disabled:true,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        form: this.$form.createForm(this),
        validatorRules:{
        handleFlag:{rules: [{ required: true, message: '请选择处理结果！' }]},
          handleContent:{rules: [{ required: true, message: '请输入处理描述！' }]},
        },
        url: {
          edit: "/user/user-feedback/edit",
        },
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        // debugger
        this.disabled = record.handleFlag == '1' ? true : false;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'handleContent'))
		  //时间格式化
        });

      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;

            let formData = Object.assign(this.model, values);
            formData.handleFlag = '1';
            //时间格式化
            console.log("console.log(formData)")
            console.log(formData)

            updateFeedback(formData).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.visible = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })

          }
        })
      },
      handleCancel () {
        this.close()
      }

    }
  }
</script>

<style lang="less" scoped>

</style>