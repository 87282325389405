<!-- 举报类型 -->
<template>
  <div>
    <crud ref="crud" :config="config" @handle="handle"> </crud>
<!--    <create-form-->
<!--      ref='createForm'-->
<!--      @ok='getList'-->
<!--    />-->
    <feedBack-modal ref="modalForm" @ok="modalFormOk"></feedBack-modal>
  </div>

</template>

<script>
import { pageFeedback, listFeedback, delFeedback } from '@/api/user/feedback'
import Crud from '@/components/crud/Crud.vue'
import CreateForm from './modules/CreateForm'
import FeedBackModal from './modules/FeedBackModal.vue'
export default {
  name: 'Type',
  components: {
    Crud,
    CreateForm,
    FeedBackModal
  },
  data() {
    return {
      config: {
        search: [
          {
            field: 'handleFlag', name: '处理状态', type: 'select', option: [
              { id: 0, name: '未处理' },
              { id: 1, name: '已处理' }
            ],
          },
          // { field: 'nickname', name: '昵称' },
          // { field: 'content', name: '反馈内容' },
          // { field: 'handleContent', name: '处理内容' }
        ],
        button: [

          { name: '删除', color: 'danger', icon: 'delete', event: 'inner-delete', multiple: true },
          { name: '导出', color: 'primary', icon: 'download', event: 'inner-export' }
        ],
        table: {
          api: '/user/user-feedback/page',
          deleteApi: '/user/user-feedback/delete',
          exportApi: '/user/user-feedback/export',
          col: [
            { type: 'index' },
            { field: 'nickName', name: '用户昵称' },
            { field: 'content', name: '反馈内容', type: 'longText' },
            { field: 'imgUrl', name: '图片', type: 'imgs' },
            { field: 'createTime', name: '反馈时间', type: 'datetime' },
            {
              field: 'handleFlag', name: '处理状态', type: 'tag', tags: [
                { name: '未处理' },
                { color: 'green', name: '已处理' }
              ]
            },
            { field: 'handleContent', name: '处理内容', type: 'longText' },
            { field: 'handleTime', name: '处理时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '处理', event: 'handle' },
              ]
            },
          ]
        },
      },

    }
  },
  methods:{
    /** 查询用户反馈列表 */
    handle(button, record) {
      this.$refs.modalForm.edit(record);
    },
    modalFormOk() {
      this.$refs.crud.getList();
    }
  }
}
</script>
